import { useMemo } from 'react';
import { type Components } from '@tecnobit-srl/components-store';
import { type Route } from '@tecnobit-srl/types';
import { useInstanceRoute } from '@tecnobit-srl/route-types-instance';

export const useRouteTypes = (): Components<{
  route: Route;
}> => {
  const instanceRoute = useInstanceRoute();
  return useMemo(
    () => ({
      ...instanceRoute,
    }),
    [instanceRoute]
  );
};
