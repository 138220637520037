import { useMemo } from 'react';
import { type Components } from '@tecnobit-srl/components-store';
import { type Content } from '@tecnobit-srl/types';
import { useContainerContent } from '@tecnobit-srl/content-types-container';
import { useColumnContent } from '@tecnobit-srl/content-types-column';
import { useMarkdownContent } from '@tecnobit-srl/content-types-markdown';
import { useTitleContent } from '@tecnobit-srl/content-types-title';
import { useImageContent } from '@tecnobit-srl/content-types-image';

export const useContentTypes = (): Components<Content> => {
  const container = useContainerContent();
  const column = useColumnContent();
  const markdown = useMarkdownContent();
  const title = useTitleContent();
  const image = useImageContent();
  return useMemo(
    () => ({
      ...container,
      ...column,
      ...markdown,
      ...title,
      ...image,
    }),
    [column, container, image, markdown, title]
  );
};
